<script setup>
import { pageTransition } from '~/helpers/animations/animations';

const route = useRoute();
const router = useRouter();
const { t } = useI18n();

definePageMeta({
  pageTransition: pageTransition,
});

const setI18nParams = useSetI18nParams();

const pageQuery = gql`
  query ($slug: String!) {
    page(slug: $slug) {
      breadcrumbs {
        id
        name
        route_id
        slug: seo_url_slug {
          cs
          en
        }
      }
      seo_meta_title
      seo_meta_description
      seo_meta_keywords
      external_link
      slug: seo_url_slug {
        cs
        en
      }
      name
      content
      parent_id
      children {
        id
        name
        image
        perex
        route_id
        external_link
        slug: seo_url_slug {
          cs
          en
        }
      }
      documents {
        name
        type
        file {
          url
        }
      }
      contacts {
        id
        name
        job
        slug: seo_url_slug {
          cs
          en
        }
        image
        group {
          name
        }
        building {
          id
          name
          seo_url_slug {
            cs
            en
          }
        }
      }
      events {
        id
        name
        type
        slug: seo_url_slug {
          cs
          en
        }
        image: image_list {
          url
          width
          height
        }
        imageCrop: image_list_crop_to
        buildings {
          id
          name
          seo_url_slug {
            cs
            en
          }
        }
        dates {
          id
          start_at {
            timestamp
          }
          end_at {
            timestamp
          }
        }
      }
    }
  }
`;

const { data } = await useAsyncQuery(pageQuery, { slug: route.params.slug });
const page = ref(data.value?.page);
const breadcrumbs = useBreadCrumbs(data.value?.page.breadcrumbs || []);

setI18nParams({
  en: {
    slug: page?.value?.slug?.en,
  },
  cs: {
    slug: page?.value?.slug?.cs,
  },
});

useHeadData({
  title: page.value?.seo_meta_title || page.value?.name || null,
  description: page.value?.seo_meta_description || null,
  keywords: page.value?.seo_meta_keywords || null,
  image: page.value?.image || page.value?.cropped_detail_image || null,
  url: route.fullPath,
});

onMounted(() => {
  if (page.value?.external_link) {
    window.location.href = page.value?.external_link;
  }

  useHead({
    script: [
      {
        children: `
        var __LMC_CAREER_WIDGET__ = {
          apiKey: 'f6d3e7307f6ef020736e360c38bd2048e6f53c1e8348937cdd48587c319c2f2a',
          widgetId: '7267203a-32f3-4aa8-abc9-d68f067761a8',
          selector: '#capybara',
          themes: ['base'],
        };
      `,
        type: 'application/javascript',
      },
      {
        src: 'https://snippet.capybara.lmc.cz/js/widget-3.x.x.min.js',
        async: true,
        defer: false,
      },
    ],
  });
});
</script>

<template>
  <main v-if="page" class="main">
    <Breadcrumbs :items="breadcrumbs" />
    <div v-if="page" class="container-max-content">
      <h1 class="heading">{{ page.name }}</h1>
      <DynamicPageContent v-if="page.content" :content="page.content" class="mt-5" />

      <!-- kariera vypis -->
      <div v-if="page.slug.cs == 'kariera'" class="mt-5">
        <!-- Tech. dokumentace: http://snippet.capybara.lmc.cz/ -->
        <div id="capybara"></div>
      </div>

      <ChildrenList v-if="page.children.length" :children="page.children" />
      <ContactList v-if="page.contacts.length" :contacts="page.contacts" />
      <DocumentList v-if="page.documents.length" :documents="page.documents" />
      <div v-if="page.events.length" class="mt-5">
        <h2 class="heading heading--2 heading--thin">
          {{ t('pages.news.detail.events') }}
        </h2>
        <EventGrid :data="page.events" class="mt-4" hide-type />
      </div>
    </div>
  </main>
</template>

<style lang="scss">
.carier-form {
  padding-top: 40px;
}

#capybara {
  .cp-job__name {
    font-weight: bold;
    margin-bottom: 25px;
    text-decoration: none;
    font-size: 30px;

    @include underline-transition(2px);
    display: inline;
  }

  .cp-detail__header-title {
    font-weight: bold;
    margin-bottom: 25px;
    text-decoration: none;
    font-size: 30px;
  }

  .cp-detail__header-info {
    text-decoration: none;
    padding: 25px 0 50px;
  }
  .cp-detail__contact {
    padding: 50px 0 25px;
  }

  .cp-detail__content {
    p:first-child {
      margin: 0;
      margin-bottom: 50px;
    }
    & > * {
      margin: 10px 0;
    }
    & > ul {
      margin-bottom: 35px;
      li {
        list-style: circle;
      }
    }
  }

  .cp-button--back {
    margin: 0 20px;
  }
}
</style>
